:root {
  --size: 280px;
  --rotate: 0deg;
}

.pieContainer {
  height: var(--size);
}

.pieBackground {
  background-color: #ccc;
  position: absolute;
  width: var(--size);
  height: var(--size);
  -moz-border-radius: calc(var(--size) / 2);
  -webkit-border-radius: calc(var(--size) / 2);
  -o-border-radius: calc(var(--size) / 2);
  border-radius: calc(var(--size) / 2);
} 

.pie {
  display: block;
  position: absolute;
  width: var(--size);
  height: var(--size);
  -moz-border-radius: calc(var(--size) / 2);
  -webkit-border-radius: calc(var(--size) / 2);
  -o-border-radius: calc(var(--size) / 2);
  border-radius: calc(var(--size) / 2);
  clip: rect(0px, calc(var(--size) / 2), var(--size), 0px);
}

.hold {
  position: absolute;
  width: var(--size);
  height: var(--size);
  -moz-border-radius: calc(var(--size) / 2);
  -webkit-border-radius: calc(var(--size) / 2);
  -o-border-radius: calc(var(--size) / 2);
  border-radius: calc(var(--size) / 2);
  clip: rect(0px, var(--size), var(--size), calc(var(--size) / 2));
}

#slice .pie {
  background-color: #06E45F;
}

.icon {
  position: absolute;
  display: block;
}

#slice {
  transition: transform 0.3s;
/*   -webkit-transform: rotate(var(--rotate));
  -moz-transform: rotate(var(--rotate));
  -o-transform: rotate(var(--rotate));
  transform: rotate(var(--rotate)); */
}
